var render = function render(){var _vm=this,_c=_vm._self._c;return _c('both-colum-page',{attrs:{"title":_vm.$t('labelManage.title'),"formData":_vm.formData,"config":_vm.config},on:{"search":_vm.search,"functionMethod":_vm.functionMethod},scopedSlots:_vm._u([{key:"left-title",fn:function(){return [_vm._v(_vm._s(_vm.$t('labelManage.leftTitle')))]},proxy:true},{key:"left",fn:function(){return [_c('ul',{staticStyle:{"margin-top":"10px"}},[_c('li',[_c('el-button',{staticStyle:{"width":"100%","margin-top":"20px"},attrs:{"type":_vm.currentLabelType === 'ble'?'primary':''},on:{"click":function($event){return _vm.switchLabelType('ble')}}},[_vm._v(" "+_vm._s(_vm.$t('labelManage.bleType'))+" ")])],1),_c('li',[_c('el-button',{staticStyle:{"width":"100%","margin-top":"20px"},attrs:{"type":_vm.currentLabelType === 'rfid'?'primary':''},on:{"click":function($event){return _vm.switchLabelType('rfid')}}},[_vm._v(" "+_vm._s(_vm.$t('labelManage.RFIDType'))+" ")])],1)])]},proxy:true}])},[(_vm.currentLabelType === 'ble')?_c('table-list',{ref:"bleTableList",attrs:{"url":'/' + _vm.$util.getLocalStorage('apiprefix') + _vm.$api.bleLabelList,"columns":_vm.bleColumns,"default-params":{
        needFullArea: 'YES',
        companyUid: _vm.$store.getters.company.uid,
      }},scopedSlots:_vm._u([{key:"area",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.fullArea(row))+" ")]}},{key:"photo",fn:function({ row }){return [(row.file)?_c('el-image',{attrs:{"src":row.file.fileHost + '/' + row.file.objectName,"preview-src-list":[row.file.fileHost + '/' + row.file.objectName]}}):_vm._e()]}}],null,false,977975151),model:{value:(_vm.tableRow),callback:function ($$v) {_vm.tableRow=$$v},expression:"tableRow"}}):_vm._e(),(_vm.currentLabelType === 'rfid')?_c('table-list',{ref:"rfidTableList",attrs:{"url":'/' + _vm.$util.getLocalStorage('apiprefix') + _vm.$api.rfidLabelList,"columns":_vm.rfidColumns,"default-params":{
        needFullArea: 'YES',
        companyUid: _vm.$store.getters.company.uid,
      }},scopedSlots:_vm._u([{key:"area",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.fullArea(row))+" ")]}},{key:"photo",fn:function({ row }){return [(row.file)?_c('el-image',{attrs:{"src":row.file.fileHost + '/' + row.file.objectName,"preview-src-list":[row.file.fileHost + '/' + row.file.objectName]}}):_vm._e()]}}],null,false,977975151),model:{value:(_vm.tableRow),callback:function ($$v) {_vm.tableRow=$$v},expression:"tableRow"}}):_vm._e(),(_vm.showDetail)?_c('detail',{attrs:{"tableRow":_vm.tableRow,"label-type":_vm.currentLabelType},on:{"closePage":_vm.closePage}}):_vm._e(),(_vm.showDialogImport)?_c('dialog-import',{attrs:{"url":'/' + _vm.$util.getLocalStorage('apiprefix') + _vm.$api.parseExcel,"default-params":{
        excelFileFrom: _vm.excelFileFrom
      },"define-down-deal":true},on:{"cancel":function($event){_vm.showDialogImport = false},"downTemplate":_vm.downTemplate}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }