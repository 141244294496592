<template>
  <both-colum-page
      :title="$t('labelManage.title')"
      :formData="formData"
      :config="config"
      @search="search"
      @functionMethod="functionMethod"
  >
    <!-- lang:字典类型 -->
    <template v-slot:left-title>{{ $t('labelManage.leftTitle') }}</template>
    <template v-slot:left>
      <ul style="margin-top: 10px">
        <li>
          <el-button
              style="width: 100%; margin-top: 20px"
              :type="currentLabelType === 'ble'?'primary':''"
              @click="switchLabelType('ble')"
          >
            {{$t('labelManage.bleType')}}
          </el-button>
        </li>
        <li>
          <el-button
              style="width: 100%; margin-top: 20px"
              :type="currentLabelType === 'rfid'?'primary':''"
              @click="switchLabelType('rfid')"
          >
            {{$t('labelManage.RFIDType')}}
          </el-button>
        </li>
      </ul>
    </template>
    <table-list
        v-if="currentLabelType === 'ble'"
        ref="bleTableList"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.bleLabelList"
        :columns="bleColumns"
        :default-params="{
          needFullArea: 'YES',
          companyUid: $store.getters.company.uid,
        }"
        v-model="tableRow"
    >
      <template v-slot:area="{ row }">
        {{ fullArea(row) }}
      </template>
      <template v-slot:photo="{ row }">
        <el-image v-if="row.file" :src="row.file.fileHost + '/' + row.file.objectName" :preview-src-list="[row.file.fileHost + '/' + row.file.objectName]"/>
      </template>
    </table-list>
    <table-list
        v-if="currentLabelType === 'rfid'"
        ref="rfidTableList"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.rfidLabelList"
        :columns="rfidColumns"
        :default-params="{
          needFullArea: 'YES',
          companyUid: $store.getters.company.uid,
        }"
        v-model="tableRow"
    >
      <template v-slot:area="{ row }">
        {{ fullArea(row) }}
      </template>
      <template v-slot:photo="{ row }">
        <el-image v-if="row.file" :src="row.file.fileHost + '/' + row.file.objectName" :preview-src-list="[row.file.fileHost + '/' + row.file.objectName]"/>
      </template>
    </table-list>
    <detail
        v-if="showDetail"
        :tableRow="tableRow"
        :label-type="currentLabelType"
        @closePage="closePage"
    />
    <dialog-import
        v-if="showDialogImport"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.parseExcel"
        :default-params="{
          excelFileFrom
        }"
        @cancel="showDialogImport = false"
        :define-down-deal="true"
        @downTemplate="downTemplate"
    />
  </both-colum-page>
</template>

<script>
import Detail from './detail'

export default {
  components: {
    Detail
  },
  data() {
    return {
      currentLabelType: 'ble',
      tableRow: null,
      // 表单页
      showDetail: false,
      showDialogImport: false,
      template:null,
      // 搜索
      formData: {
        installAreaId: null,
      },
      config: [
        // // lang:账号类型
        // {
        //   tag: 'el-select',
        //   label: this.$t('adminAccount.type'),
        //   prop: 'type',
        //   span: 8,
        //   tagProps: {
        //     options: this.$dict.userAccountType(),
        //   },
        // },
        // lang:订阅模式
        // {
        //   tag: 'el-select',
        //   label: this.$t('adminAccount.subscribeType'),
        //   prop: 'subscribeType',
        //   span: 8,
        //   tagProps: {
        //     options: this.$dict.subscribeType(),
        //   },
        // },
        // lang:是否在线支持人员
        {
          tag: 'el-cascader',
          label: this.$t('labelManage.installArea'),
          prop: 'installAreaId',
          rules: {
            noNull: true,
          },
          span: 8,
          tagProps: {
            options: [],
            props: {
              label: 'value',
              value: 'id',
            },
          },
        },
      ],
      bleColumns: [
        // lang:标签名称
        {
          label: this.$t('labelManage.labelName'),
          prop: 'name',
        },
        // lang:区域
        {
          tag: 'slot-content',
          label: this.$t('labelManage.area'),
          prop: 'area',
          width: 200,
        },
        // lang:类型
        // {
        //   tag: 'slot-content',
        //   label: this.$t('labelManage.labelType'),
        //   prop: 'labelType',
        // },
        // lang:标签ID
        {
          label: this.$t('labelManage.labelID'),
          prop: 'labelId',
        },
        // lang:安装位置
        {
          label: this.$t('labelManage.installAddress'),
          prop: 'installAddress',
        },
        // lang:被发现名称
        {
          label: this.$t('labelManage.discoveredName'),
          prop: 'discoverName',
        },
        // lang:RSSI:0.5m内
        {
          label: this.$t('labelManage.RSSI0_5'),
          prop: 'rssiZeroPointFiveInterval',
        },
        // lang:RSSI:1.0m内
        {
          label: this.$t('labelManage.RSSI1_0'),
          prop: 'rssiOneInterval',
        },
        // lang:RSSI:1.5m内
        {
          label: this.$t('labelManage.RSSI1_5'),
          prop: 'rssiOnePointFiveInterval',
        },
        // lang:电池余量
        // {
        //   label: this.$t('labelManage.LOBAT'),
        //   prop: 'accountId',
        // },
        // lang:现场照片
        {
          tag: 'slot-content',
          label: this.$t('labelManage.sitePhotos'),
          prop: 'photo',
          width: 200,
        },
      ],
      rfidColumns: [
        // lang:标签名称
        {
          label: this.$t('labelManage.labelName'),
          prop: 'name',
        },
        // lang:区域
        {
          tag: 'slot-content',
          label: this.$t('labelManage.area'),
          prop: 'area',
          width: 200,
        },
        // lang:类型
        // {
        //   tag: 'slot-content',
        //   label: this.$t('labelManage.labelType'),
        //   prop: 'labelType',
        // },
        // lang:标签ID
        {
          label: this.$t('labelManage.labelID'),
          prop: 'labelId',
        },
        // lang:安装位置
        {
          label: this.$t('labelManage.installAddress'),
          prop: 'installAddress',
        },
        // lang:被发现名称
        // {
        //   label: this.$t('labelManage.discoveredName'),
        //   prop: 'discoverName',
        // },
        // lang:RSSI:0.5m内
        // {
        //   label: this.$t('labelManage.RSSI0_5'),
        //   prop: 'rssiOneInterval',
        // },
        // lang:RSSI:1.0m内
        // {
        //   label: this.$t('labelManage.RSSI1_0'),
        //   prop: 'rssiOnePointFiveInterval',
        // },
        // lang:RSSI:1.5m内
        // {
        //   label: this.$t('labelManage.RSSI1_5'),
        //   prop: 'rssiZeroPointFiveInterval',
        // },
        // lang:电池余量
        // {
        //   label: this.$t('labelManage.LOBAT'),
        //   prop: 'accountId',
        // },
        // lang:现场照片
        {
          tag: 'slot-content',
          label: this.$t('labelManage.sitePhotos'),
          prop: 'photo',
          width: 200,
        },
      ],
    }
  },
  computed: {
    deleteApi() {
      return this.currentLabelType === 'ble'?this.$api.bleLabelDelete:this.$api.rfidLabelDelete
    },
    downloadTemplateApi() {
      return this.currentLabelType === 'ble'?this.$api.bleLabelDownloadTemplate:this.$api.rfidLabelDownloadTemplate
    },
    excelFileFrom() {
      return this.currentLabelType === 'ble'?'BLE_LABEL_IMPORT_FILE':'RFID_LABEL_IMPORT_FILE'
    }
  },
  watch: {
    currentLabelType(val) {
      this.$nextTick(() => {
        this.search()
      })
    }
  },
  mounted() {
    this.dictFindTree()
  },
  methods: {
    dictFindTree() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictFindTree,
        data: {
          language: 'zhCN',
          dictType: 'LABEL_INSTALL_AREA',
        },
      }).then(res => {
        this.config[0].tagProps.options = res
      })
    },
    downTemplate() {
      const downloadDoc = (content, filename) => {
        var eleLink = document.createElement('a')
        eleLink.download = filename + '.xlsx'
        eleLink.style.display = 'none'
        // 字符内容转变成blob地址
        var blob = new Blob([content])
        eleLink.href = URL.createObjectURL(blob)
        // 自动触发点击
        document.body.appendChild(eleLink)
        eleLink.click()
        // 然后移除
        document.body.removeChild(eleLink)
      }
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.downloadTemplateApi,
        data: {},
        defineDeal: true,
      }).then(res => {
         return downloadDoc(res, this.currentLabelType + '-label-template')
      })
    },
    fullArea(row) {
      let area = ''
      if(row.installArea.parent) {
        const one = row.installArea.parent.filter(item => item.level === 'ONE')
        const two = row.installArea.parent.filter(item => item.level === 'TWO')
        if(one.length > 0) {
          area += one[0].value
        }
        if(two.length > 0) {
          area += two[0].value
        }
      }
      area += row.installArea.value
      return area
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      const formData = this.$util.copyData(this.formData)
      if(formData.installAreaId !== null && formData.installAreaId.length > 0) {
        formData.installAreaId = formData.installAreaId.pop()
      } else {
        formData.installAreaId = null
      }
      if (this.currentLabelType === 'ble') {
        this.$refs.bleTableList.searchList(formData, isActiveQuery)
      }
      if (this.currentLabelType === 'rfid') {
        this.$refs.rfidTableList.searchList(formData, isActiveQuery)
      }
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      console.log(item)
      const { method } = item
      if (method !== 'add' && method !== 'batchImport' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      if (
          method === 'add' &&
          this.tableRow &&
          this.params.dictType === 'VERSION_CATEGORY'
      ) {
        this.$element.showMsg(this.$t('base.canAddFirstCate'), 'warning')
        return
      }
      switch (method) {
        case 'add':
        case 'edit':
        case 'view':
          this.showDetail = true
          break
        case 'del':
          this.deleteMethod()
          break
        case 'batchImport':
          this.showDialogImport = true
              break
        default: // do something
      }
    },
    // 删除
    deleteMethod() {
      this.$element
          .showConfirm({
            content: this.$t('base.deleteConfirm'),
          })
          .then(() => {
            this.$ajax({
              url: '/' + this.$util.getLocalStorage('apiprefix') + this.deleteApi,
              data: {ids:[this.tableRow.id]},
            }).then(() => {
              // lang:删除成功
              this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
              this.search()
            })
          })
    },
    switchLabelType(type) {
      this.currentLabelType = type
    },
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    }
  },
}
</script>
